import AdminMenu from "./AdminMenu"
import { useState, useEffect } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Column } from "./components/Column/Column";
import { Input } from "./components/Input/Input";
import {PATH} from "../constans"
import BackgroungImage from "../../assets/4873152.jpg"
import "./sorting.css"

const Sorting = () => {
  // --------------  GET DATA FROM DATABASE ----------------------
  const [tasks, setTasks] = useState([])
  const [category, setCategory] = useState(null)	
//   let category = ''
  const getData = (e) => {
    e.preventDefault()
    setCategory(()=> e.target.dataset.category)
    //  category = e.target.dataset.category
      fetch(PATH + "read.php?category=" + e.target.dataset.category)
        .then(res => res.json())
        .then(result => {
            setTasks(result.data)
      })
  }
  const token = sessionStorage.getItem("userData")

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getTaskPos = (id) => tasks.findIndex((task) => task.id === id);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setTasks((tasks) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);
      
      return arrayMove(tasks, originalPos, newPos);
    });
  };

  const showTaks = () => {
    const tasksBackup = []
    tasks.map((task,index)=> tasksBackup.push({...task, index}))
    setTasks((tasksBackup) => [...tasksBackup]);
    console.log(tasks)
    fetch(PATH + 'react-drag-and-drop-dnd-kit-II.php?category=' + category, {
        method: 'POST',
        headers: {
          "X-Auth-Token" : token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(tasksBackup)
    }).then((data)=>{
        console.log(data)
    }).catch(err => { console.log(err) })
  }


  return (
    <div className="sorting" style={{ backgroundImage: `url(${BackgroungImage})` }}>
      <AdminMenu />
      <section className='delete-data sorting-data'>
        
      <div className="admin-menu">
        <div className="hercegnos_ruhak" data-category="hercegnos_ruhak" onClick={getData}>hercegnos_ruhak</div>
        <div className="a_vonalu" data-category="sello_a_vonalu" onClick={getData}>sello_a_vonalu</div>
        <div className="plus_size" data-category="plus_size" onClick={getData}>plus_size</div>
        <div className="klasszikus_es_kismama" data-category="klasszikus_es_kismama" onClick={getData}>klasszikus_es_kismama</div>
        <div className="szalagavatos_ruhak" data-category="szalagavatos_ruhak" onClick={getData}>szalagavatos_ruhak</div>
        <div className="kislany_ruhak" data-category="kislany_ruhak" onClick={getData}>Koszorúslány</div>
        <div className="alkalmi_ruhak" data-category="alkalmi_ruhak" onClick={getData}>alkalmi_ruhak</div>
        <div className="menyecske_ruhak" data-category="menyecske_ruhak" onClick={getData}>menyecske_ruhak</div>
        <div className="kontosok" data-category="kontosok" onClick={getData}>Köntösök</div>
      </div>
      
       {tasks.length > 0 && (
        <>
          <div className="sort-container">
            <h1>Képek sorbarendezése: {tasks[0].category}</h1> 
            <button onClick={showTaks} className="sort-button">Képek mentése</button>
          </div>
            <div >
                <DndContext
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        onDragEnd={handleDragEnd}
                    >
                    <Column id="toDo" tasks={tasks} target={tasks[0].category}/>
                </DndContext>
            </div>
        </>
       )}
      
    </section>
    </div>
  )
}

export default Sorting
